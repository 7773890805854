<template>
  <input
    type="number"
    :min="min"
    :max="max"
    :step="step"
    v-model="computedValue"
    @blur="onBlur()"
  />
</template>

<script>
export default {
  name: "NumberField",
  props: {
    value: Number,
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  data: function () {
    return {
      v: this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.v;
      },
      set(value) {
        this.v = value;
        if (this.v < this.min) return; // If the value is less than the min, assume the user is still typing and don't propagate the value
        this.$emit("input", this.constrainValue(value));
      },
    },
  },
  methods: {
    onBlur() {
      this.computedValue = this.constrainValue(this.v);
    },
    constrainValue(value) {
      return Math.min(Math.max(this.min, value), this.max);
    },
  },
};
</script>