var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.computedValue,
        expression: "computedValue",
      },
    ],
    attrs: { type: "number", min: _vm.min, max: _vm.max, step: _vm.step },
    domProps: { value: _vm.computedValue },
    on: {
      blur: function ($event) {
        return _vm.onBlur()
      },
      input: function ($event) {
        if ($event.target.composing) return
        _vm.computedValue = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }